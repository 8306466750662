/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { FormGroup, Input, Row, Col } from "reactstrap";

import ReactPlayer from 'react-player';

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step3Select: null
    };
  }
  render() {
    return (
      <>
        <form>
          <Row className="justify-content-center">
            <Col sm="12">
              <h5 className="info-text">Pat yourself on the back!</h5>
              <div style={{position: "relative", paddingTop: "36.25%", marginLeft: "18%", marginRight: "18%"}}>
                <ReactPlayer
                  url='https://merlinholmes.wistia.com/medias/ec5x7udups'
                  controls="true"
                  className="ml-auto mr-auto"
                  style={{position: "absolute", top: 0, left: 0}}
                  width='100%'
                  height='100%'
                />
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
