/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import ReactPlayer from 'react-player';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  Form,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCollapse
} from "reactstrap";

import fetch from "cross-fetch";

import hundredADay from "assets/img/100-a-day-small.png";
import personalIncomeCoaching from "assets/img/coaching-small.png";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class Bonuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      scheduleClicked: false,
      alert: null,
      day: new Date(),
      time: "7:30"
    };
  }
  componentDidMount() {
    console.log(this.state.password);
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUserDetails&Email=' + getCookie('user'))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        this.setState ({
          email: user[0].Email,
          affiliateID: user[0].AffiliateID,
          firstName: user[0].FirstName,
          lastName: user[0].LastName,
          address: user[0].Address,
          city: user[0].City,
          country: user[0].Country,
          postalCode: user[0].PostalCode,
          phone: user[0].Phone,
          password: user[0].Password,
          newPassword: user[0].Password,
          passwordConfirmation: user[0].Password
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  handleChange = (target, event) => {
    this.setState({
      [target]: event.target.value
    });
  }
  handleDateChange = (day) => {
    this.setState({
      day: day
    });
  }
  handleSave = () => {
    //console.log('schedule!');
    let day = new Date(this.state.day);
    let time = this.state.time + ":00";
    let timeStamp = day.toLocaleDateString() + " " + time;
    //console.log(timeStamp);
    if (this.state.email !== "" && this.state.firstName !== "" && this.state.lastName !== "" && this.state.phone !=- "") {
      fetch('https://hooks.zapier.com/hooks/catch/10128133/bycskcc?email=' + this.state.email + '&firstName=' + this.state.firstName + '&lastName=' + this.state.lastName + '&phone=' + this.state.phone + '&day=' + day.toDateString() + '&time=' + this.state.time + '&timeStamp=' + timeStamp);
      this.setState({
        scheduleClicked: true,
        alert: (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Your Appointment Has Been Scheduled. Please make sure to mark it on your calendar so you do not forget. One of our coaches will call you at the number you provided at the time you chose."
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          />
        )
      });
    } else {
      this.setState({
        scheduleClicked: false,
        alert: (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Please fill out all fields before scheduling your appointment."
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          />
        )
      });
    }
  }
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  validDate = (current) => {
    let yesterday = moment().subtract( 1, 'day' );
    if (!current.isAfter( yesterday )) {
      return false;
    }
    return current.day() !== 0 && current.day() !== 6;
  };
  render() {
    return (
      <>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h2">$100 A Day</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4" className="text-center">
                      <img src={hundredADay} style={{width: "80%", height: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "10%"}} />
                      <Button color="primary" onClick={() => window.open("https://members.thefasttracks.com/login", "_blank")}>Access My Account</Button>
                    </Col>
                    <Col md="8">
                      <div style={{position: "relative", paddingTop: "36.25%", marginLeft: "18%", marginRight: "18%", marginTop: 50}}>
                        <ReactPlayer
                          url='https://merlinholmes.wistia.com/medias/lryzo48f1c'
                          controls="true"
                          className="ml-auto mr-auto"
                          style={{position: "absolute", top: 0, left: 0}}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h2">Personal Income Coaching</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4" className="text-center">
                      <img src={personalIncomeCoaching} style={{width: "80%", height: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "10%"}} />
                      <Button color="primary" id="toggler">
                        Schedule Your Coaching Consultation
                      </Button>
                    </Col>
                    <Col md="8">
                      <div style={{position: "relative", paddingTop: "36.25%", marginLeft: "18%", marginRight: "18%", marginTop: 50}}>
                        <ReactPlayer
                          url='https://merlinholmes.wistia.com/medias/lwexsfm9qw'
                          controls="true"
                          className="ml-auto mr-auto"
                          style={{position: "absolute", top: 0, left: 0}}
                          width='100%'
                          height='100%'
                        />
                      </div>
                    </Col>
                  </Row>
                  <UncontrolledCollapse toggler="#toggler">
                    <Row className="mt-5">
                      <Col className="text-left ml-2" sm="6">
                        <h3>Schedule Your Coaching Consultation Below</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-left ml-2" xl="6" lg="7" md="8" sm="10" xs="12">
                        <p>Please enter and verify your contact info below, some of it may be pre-filled for you. It is very important that your information is correct here, especially your phone number or we won't be able to reach you.</p>
                        <Form>
                          <Row>
                            <Col className="pr-md-1" md="6">
                              <FormGroup className="mr-3">
                                <label>First Name</label>
                                <Input value={this.state.firstName} onChange={e => this.handleChange('firstName', e)} type="text" />
                              </FormGroup>
                            </Col>
                            <Col className="pl-md-1" md="6">
                              <FormGroup className="mr-3">
                                <label>Last Name</label>
                                <Input value={this.state.lastName} onChange={e => this.handleChange('lastName', e)} type="text" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mr-3">
                                <label>Email</label>
                                <Input
                                  value={this.state.email} onChange={e => this.handleChange('email', e)}
                                  placeholder=""
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mr-3">
                                <label>Phone</label>
                                <Input
                                  value={this.state.phone} onChange={e => this.handleChange('phone', e)}
                                  placeholder=""
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <p>Please choose the best day and time for your coach to call you using the drop downs below. Make sure you choose a time when you will be available. Currently, times are available Mon-Fri from 7:30 am to 5pm Pacific time.</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-1" md="6">
                              {/*<FormGroup className="mr-3">
                                <label>Contact Day</label>
                                <Input value={this.state.day} onChange={e => this.handleChange('day', e)} type="select" style={{backgroundColor: "#27293d"}}>
                                  <option>Monday</option>
                                  <option>Tuesday</option>
                                  <option>Wednesday</option>
                                  <option>Thursday</option>
                                  <option>Friday</option>
                                </Input>
                              </FormGroup>*/}
                              <FormGroup className="mr-3">
                                <label>Contact Day</label>
                                <ReactDatetime
                                  inputProps={{
                                    className: "form-control",
                                    placeholder: "Choose A Date"
                                  }}
                                  timeFormat={false}
                                  isValidDate={ this.validDate }
                                  value={this.state.day}
                                  onChange={e => this.handleDateChange(e)}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pl-md-1" md="6">
                              <FormGroup className="mr-3">
                              <label>Contact Time</label>
                              <Input value={this.state.time} onChange={e => this.handleChange('time', e)} type="select" style={{backgroundColor: "#27293d"}}>
                                <option value="7:30">7:30am Pacifc/8:30am Mnt/9:30am Cen/10:30am Est</option>
                                <option value="8:00">8:00am Pacifc/9:00am Mnt/10:00am Cen/11:00am Est</option>
                                <option value="8:30">8:30am Pacifc/9:30am Mnt/10:30am Cen/11:30am Est</option>
                                <option value="9:00">9:00am Pacifc/10:00am Mnt/11:00am Cen/12:00pm Est</option>
                                <option value="9:30">9:30am Pacifc/10:30am Mnt/11:30am Cen/12:30pm Est</option>
                                <option value="10:00">10:00am Pacifc/11:00am Mnt/12:00pm Cen/1:00pm Est</option>
                                <option value="10:30">10:30am Pacifc/11:30am Mnt/12:30pm Cen/1:30pm Est</option>
                                <option value="11:00">11:00am Pacifc/12:00pm Mnt/1:00pm Cen/2:00pm Est</option>
                                <option value="11:30">11:30am Pacifc/12:30pm Mnt/1:30pm Cen/2:30pm Est</option>
                                <option value="12:00">12:00pm Pacifc/1:00pm Mnt/2:00pm Cen/3:00pm Est</option>
                                <option value="12:30">12:30pm Pacifc/1:30pm Mnt/2:30pm Cen/3:30pm Est</option>
                                <option value="13:00">1:00pm Pacifc/2:00pm Mnt/3:00pm Cen/4:00pm Est</option>
                                <option value="13:30">1:30pm Pacifc/2:30pm Mnt/3:30pm Cen/4:30pm Est</option>
                                <option value="14:00">2:00pm Pacifc/3:00pm Mnt/4:00pm Cen/5:00pm Est</option>
                                <option value="14:30">2:30pm Pacifc/3:30pm Mnt/4:30pm Cen/5:30pm Est</option>
                                <option value="15:00">3:00pm Pacifc/4:00pm Mnt/5:00pm Cen/6:00pm Est</option>
                                <option value="15:30">3:30pm Pacifc/4:30pm Mnt/5:30pm Cen/6:30pm Est</option>
                                <option value="16:00">4:00pm Pacifc/5:00pm Mnt/6:00pm Cen/7:00pm Est</option>
                                <option value="16:30">4:30pm Pacifc/5:30pm Mnt/6:30pm Cen/7:30pm Est</option>
                                <option value="17:00">5:00pm Pacifc/6:00pm Mnt/7:00pm Cen/8:00pm Est</option>
                              </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Button color="primary" disabled={this.state.scheduleClicked} onClick={e => this.handleSave()}>Schedule Appointment</Button>
                              </FormGroup>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </UncontrolledCollapse>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Bonuses;
