/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import Particles from 'react-particles-js';
import fetch from 'cross-fetch';

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    alert: null
  };
  componentDidMount() {
    document.body.classList.toggle("login-page");
    let user=getCookie('user');
    //console.log(user + " mount");
    if (user !== '') {
      //console.log('user detected (mount)');
      window.location.href = '/admin/dashboard';
    }
  }
  componentDidUpdate() {
    let user=getCookie('user');
    //console.log(user);
    if (user !== '') {
      //console.log('user detected (update)');
      window.location.href = '/admin/dashboard';
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }
  doForgot = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Unable to log in. Please check username and password."
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        />
      )
    });
  }
  doLogin = () => {
    let user=getCookie('user');
    //console.log(user + " mount");
    if (user !== '') {
      //console.log('user detected (mount)');
      window.location.href = '/admin/dashboard';
    }
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUser&email=' + encodeURIComponent(this.state.email) + '&password=' + encodeURIComponent(this.state.password))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        console.log(user);
        if (user[0].ID !== "-1") {
          setCookie('user', user[0].Email, 365);
          window.location.href = '/admin/dashboard';
        } else {
          console.log('failed login');
          this.setState({
            alert: (
              <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Unable to log in. Please check username and password."
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="warning"
                btnSize=""
              />
            )
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
  handleInput = (target, event) => {
    //console.log(target + event.target.value);
    this.setState({
      [target]: event.target.value
    });
  }
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  render() {
    return (
      <>
        <div className="content">
          {this.state.alert}
          <div style={{position: "absolute", top: "0px", bottom: "0px", right: "0px", left: "0px"}}>
            <Particles
              width="100vw"
              height="100vh"
              params={{
                "particles": {
                    "number": {
                        "value": 75
                    },
                    "size": {
                        "value": 1
                    }
                },
                "interactivity": {
                    "events": {
                        "onhover": {
                            "enable": true,
                            "mode": "repulse"
                        }
                    }
                }
            }} />
          </div>
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader>
                    {/*<img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />*/}
                    <CardTitle tag="h1" style={{height: 80}} className="text-center">
                      <div className="text-center" style={{width: "40%"}}>
                        <img
                          alt="..."
                          src={require("assets/img/LOGO-1-RGB.png")}
                          style={{width: "60%", marginTop: 40, marginLeft: "auto", marginRight: "auto"}}
                        />
                      </div>

                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.emailFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        onFocus={e => this.setState({ emailFocus: true })}
                        onBlur={e => this.setState({ emailFocus: false })}
                        onChange={e => this.handleInput('email', e)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.passFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={e => this.setState({ passFocus: true })}
                        onBlur={e => this.setState({ passFocus: false })}
                        onChange={e => this.handleInput('password', e)}
                      />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      onClick={this.doLogin}
                      size="lg"
                    >
                      Get Started
                    </Button>
                    {/*<a href="#">Forgot password?</a>*/}
                    {/*<div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Need Help?
                        </a>
                      </h6>
                    </div>*/}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default Login;
