/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";
import fetch from 'cross-fetch';

// reactstrap components
import { Col, Button, UncontrolledCollapse, Card, CardBody } from "reactstrap";

// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

var steps = [
  {
    stepName: "ClickBank",
    stepIcon: "tim-icons icon-laptop",
    component: Step1
  },
  {
    stepName: "Enter Nickname",
    stepIcon: "tim-icons icon-badge",
    component: Step2
  },
  {
    stepName: "Done",
    stepIcon: "tim-icons icon-money-coins",
    component: Step3
  }
];

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      affiliateID: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
      phone: "",
      password: ""
    };
  }
  componentDidMount() {
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUserDetails&Email=' + getCookie('user'))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        this.setState ({
          email: user[0].Email,
          affiliateID: user[0].AffiliateID,
          firstName: user[0].FirstName,
          lastName: user[0].LastName,
          address: user[0].Address,
          city: user[0].City,
          country: user[0].Country,
          postalCode: user[0].PostalCode,
          phone: user[0].Phone,
          password: user[0].Password
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      fetch('https://perpetualincomeengine.com/server/server.php?method=setPieUserDetails&Email='
        + this.state.email
        + "&AffiliateID=" + this.state.affiliateID
        + "&FirstName=" + this.state.firstName
        + "&LastName=" + this.state.lastName
        + "&Address=" + this.state.address
        + "&City=" + this.state.city
        + "&Country=" + this.state.country
        + "&PostalCode=" + this.state.postalCode
        + "&Password=" + this.state.password
        + "&Phone=" + this.state.phone
      );
    }
  }
  handleClick = () => {
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUserDetails&Email=' + getCookie('user'))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        this.setState ({
          email: user[0].Email,
          affiliateID: user[0].AffiliateID,
          firstName: user[0].FirstName,
          lastName: user[0].LastName,
          address: user[0].Address,
          city: user[0].City,
          country: user[0].Country,
          phone: user[0].Phone,
          postalCode: user[0].PostalCode
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  onWizardFinish() {
    window.location.href = '/admin/dashboard';
  }
  render() {
    return (
      <>
        <div className="content" onClick={this.handleClick}>
          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Get Started In 3 Simple Steps"
              description="You've got this!"
              headerTextCenter
              finishButtonClasses="btn-wd btn-info"
              nextButtonClasses="btn-wd btn-info"
              previousButtonClasses="btn-wd"
              progressbar
              color="blue"
              finishButtonClick={this.onWizardFinish}
            />
            <p>Current Nickname: {this.state.affiliateID}</p>
          </Col>
          <Col md="12">
            <Button color="primary" id="faq0" style={{ marginBottom: '1rem' }}>
              Need Help Or Have A Question?
            </Button>
            <UncontrolledCollapse toggler="#faq0">
              <Card>
                <CardBody>
                  <ul>
                    <li>Please send all questions to support@thefasttracks.com and we will get back to you within 24-48 business hours.</li>
                  </ul>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </Col>
        </div>
      </>
    );
  }
}

export default Wizard;
