/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import JsGravatar from 'js-gravatar';
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

import fetch from "cross-fetch";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      affiliateID: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
      password: "",
      newPassword: "",
      passwordConfirmation: "",
      phone: "",
      passwordsMatch: {"valid": true},
      saveClicked: false,
      alert: null
    };
  }
  componentDidMount() {
    console.log(this.state.password);
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUserDetails&Email=' + getCookie('user'))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        this.setState ({
          email: user[0].Email,
          affiliateID: user[0].AffiliateID,
          firstName: user[0].FirstName,
          lastName: user[0].LastName,
          address: user[0].Address,
          city: user[0].City,
          country: user[0].Country,
          postalCode: user[0].PostalCode,
          phone: user[0].Phone,
          password: user[0].Password,
          newPassword: user[0].Password,
          passwordConfirmation: user[0].Password
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state.password);
    if (this.state.saveClicked) {
      fetch('https://perpetualincomeengine.com/server/server.php?method=setPieUserDetails&Email='
        + this.state.email
        + "&AffiliateID=" + this.state.affiliateID
        + "&FirstName=" + this.state.firstName
        + "&LastName=" + this.state.lastName
        + "&Address=" + this.state.address
        + "&City=" + this.state.city
        + "&Country=" + this.state.country
        + "&PostalCode=" + this.state.postalCode
        + "&Password=" + this.state.password
        + "&Phone=" + this.state.phone
      );

    }
  }
  handleChange = (target, event) => {
    this.setState({
      [target]: event.target.value
    });
  }
  handleSave = () => {
    if (this.state.passwordsMatch.valid) {
      console.log('save event');
      this.setState({
        saveClicked: true,
        alert: (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Settings saved."
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          />
        )
      });
    }
  }
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  setPassword = (target, event) => {
    //console.log('password change attempt');
    let password = this.state.newPassword;
    let passwordConfirmation = this.state.passwordConfirmation;

    if (target === "newPassword") {
      password = event.target.value;
      this.setState ({
        newPassword: password
      });
    }

    if (target === "passwordConfirmation") {
      passwordConfirmation = event.target.value;
      this.setState ({
        passwordConfirmation: passwordConfirmation
      });
    }

    //console.log(password, passwordConfirmation);

    if (password === passwordConfirmation) {
      console.log('passwords match');
      if (password.length > 4) {
        this.setState ({
          passwordsMatch: {"valid": true},
          password: password
        });
      } else {
        this.setState ({
          passwordsMatch: {"invalid": true}
        });
      }

    } else {
      this.setState ({
        passwordsMatch: {"invalid": true}
      });
    }
  }
  render() {
    const avatar = JsGravatar({ email: getCookie('user'), size: 400, wavatar: 'mp' });
    return (
      <>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/*<Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Company (disabled)</label>
                          <Input
                            defaultValue="Creative Code Inc."
                            disabled
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="3">
                        <FormGroup>
                          <label>Username</label>
                          <Input defaultValue="michael23" type="text" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Email address</label>
                          <Input placeholder="mike@email.com" type="email" />
                        </FormGroup>
                      </Col>
                    </Row>*/}
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Password (must be 5 characters or more)</label>
                          <Input {...this.state.passwordsMatch} value={this.state.newPassword} onChange={e => this.setPassword('newPassword', e)} type="password" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input {...this.state.passwordsMatch} value={this.state.passwordConfirmation} onChange={e => this.setPassword('passwordConfirmation', e)} type="password" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>First Name</label>
                          <Input value={this.state.firstName} onChange={e => this.handleChange('firstName', e)} type="text" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input value={this.state.lastName} onChange={e => this.handleChange('lastName', e)} type="text" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            value={this.state.address} onChange={e => this.handleChange('address', e)}
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>City</label>
                          <Input value={this.state.city} onChange={e => this.handleChange('city', e)} type="text" />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Country</label>
                          <Input value={this.state.country} onChange={e => this.handleChange('country', e)} type="text" />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Postal Code</label>
                          <Input value={this.state.postalCode} onChange={e => this.handleChange('postalCode', e)} placeholder="ZIP Code" type="text" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Phone</label>
                          <Input
                            value={this.state.phone} onChange={e => this.handleChange('phone', e)}
                            placeholder=""
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Button onClick={e => this.handleSave()}>Save Profile</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/*<Row>
                      <Col md="8">
                        <FormGroup>
                          <label>About Me</label>
                          <Input
                            cols="80"
                            defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                            placeholder="Here can be your description"
                            rows="4"
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>*/}
                  </Form>
                </CardBody>
                <CardFooter>
                  {/*<Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>*/}
                </CardFooter>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={avatar}
                      />
                      <h5 className="title">{this.state.firstName} {this.state.lastName}</h5>
                    </a>
                    <p className="description">Member</p>
                  </div>
                  {/*<div className="card-description">
                    Do not be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                  </div>*/}
                </CardBody>
                <CardFooter>
                  {/*<div className="button-container">
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="google">
                      <i className="fab fa-google-plus" />
                    </Button>
                  </div>*/}
                </CardFooter>
              </Card>
            </Col>
            <Col md="12">
              <Button color="primary" id="faq0" style={{ marginBottom: '1rem' }}>
                Need Help Or Have A Question?
              </Button>
              <UncontrolledCollapse toggler="#faq0">
                <Card>
                  <CardBody>
                    <ul>
                      <li>Please send all questions to support@thefasttracks.com and we will get back to you within 24-48 business hours.</li>
                    </ul>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default User;
