/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from 'react-player';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";

class Pricing extends React.Component {
  componentDidMount() {
    //document.body.classList.toggle("pricing-page");
  }
  componentWillUnmount() {
    //document.body.classList.toggle("pricing-page");
  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h2">What Is Traffic Boost?</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div style={{position: "relative", paddingTop: "36.25%", marginLeft: "18%", marginRight: "18%"}}>
                    <ReactPlayer
                      url='https://merlinholmes.wistia.com/medias/16qzj86ol6'
                      controls="true"
                      className="ml-auto mr-auto"
                      style={{position: "absolute", top: 0, left: 0}}
                      width='100%'
                      height='100%'
                    />
                  </div>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h1 className="title">Ready To Turbo Charge Your Income Engine?</h1>
              <h4 className="description">
                Select your Traffic Boost plan below.
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6">
              <Card className="card-pricing card-primary">
                <CardBody>
                  <CardTitle tag="h1">level 1</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-primary.png")}
                  />
                  <ListGroup>
                    <ListGroupItem>100 premium clicks</ListGroupItem>
                    <ListGroupItem>one time sales</ListGroupItem>
                    <ListGroupItem>recurring sales</ListGroupItem>
                    <ListGroupItem>high ticket items</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <h3 className="text-on-front">
                      <span>$</span>
                      97
                    </h3>
                    <h5 className="text-on-back">97</h5>
                    <p className="plan">Level 1 Boost</p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <Button className="btn-round btn-just-icon" color="primary">
                    Get started
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-pricing card-success card-raised">
                <CardBody>
                  <CardTitle tag="h1">level 2</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-success.png")}
                  />
                  <ListGroup>
                    <ListGroupItem>250 premium clicks</ListGroupItem>
                    <ListGroupItem>one time sales</ListGroupItem>
                    <ListGroupItem>recurring sales</ListGroupItem>
                    <ListGroupItem>high ticket items</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <h3 className="text-on-front">
                      <span>$</span>
                      237
                    </h3>
                    <h5 className="text-on-back">237</h5>
                    <p className="plan">Level 2 Boost</p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <Button className="btn-round btn-just-icon" color="success">
                    Get started
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6">
              <Card className="card-pricing card-warning">
                <CardBody>
                  <CardTitle tag="h1">level 3</CardTitle>
                  <CardImg
                    alt="..."
                    src={require("assets/img/card-warning.png")}
                  />
                  <ListGroup>
                    <ListGroupItem>500 premium clicks</ListGroupItem>
                    <ListGroupItem>one time sales</ListGroupItem>
                    <ListGroupItem>recurring sales</ListGroupItem>
                    <ListGroupItem>high ticket items</ListGroupItem>
                  </ListGroup>
                  <div className="card-prices">
                    <h3 className="text-on-front">
                      <span>$</span>
                      467
                    </h3>
                    <h5 className="text-on-back">467</h5>
                    <p className="plan">Level 3 Boost</p>
                  </div>
                </CardBody>
                <CardFooter className="text-center mb-3 mt-3">
                  <Button className="btn-round btn-just-icon" color="warning">
                    Get started
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" md="12">
              <h3 className="title">Level 1 Boost</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <p>
                Our most affordable boost, this plan is perfect for sampling
                what more traffic will do for your P.I.E. account. You get the
                same premium traffic as the other boosts, including the
                possibility of recurring and high ticket item sales.
              </p>
            </Col>
            <Col className="ml-auto" md="6">
              <div className="progress-container progress-warning">
                <span className="progress-badge">100 premium clicks</span>
                <Progress max="100" value="25" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" md="12">
              <h3 className="title">Level 2 Boost</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <p>
                Our most popular boost, this plan is takes it up a level. More
                clicks translates into more sales. You get the same premium
                traffic as the other boosts, including the possibility of
                recurring and high ticket item sales.
              </p>
            </Col>
            <Col className="ml-auto" md="6">
              <div className="progress-container progress-warning">
                <span className="progress-badge">250 premium clicks</span>
                <Progress max="100" value="55" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" md="12">
              <h3 className="title">Level 3 Boost</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <p>
                Our best boost, this plan delivers an amazing 500 high quality
                visitors, all for you. You get the same premium traffic as the
                other boosts, the best price per click, and the best chance to
                make recurring and high ticket item sales.
              </p>
            </Col>
            <Col className="ml-auto" md="6">
              <div className="progress-container progress-warning">
                <span className="progress-badge">500 premium clicks</span>
                <Progress max="100" value="100" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" md="6">
              <h3 className="title">Important Details</h3>
              <p>
                Traffic Boost is the exact same traffic that is already in use
                in the P.I.E. system. Boosts represent additional traffic on top
                of the traffic your account is already getting.
              </p>
              <br />
              <p>
                Boosts are applied monthly, beginning the next calendar month.
                If you buy a boost in January, your extra traffic will arrive in
                February.
              </p>
              <br />
              <p>
                Sales are not guaranteed. With our current sales rates, however,
                each Traffic Boost package is highly likely to result in extra
                sales.
              </p>
              <br />
              <p>
                Unlike your P.I.E. membership, we cannot offer refunds on your
                Traffic Boost traffic. Because of this, it is very important to
                make sure your account is set up properly. We strongly suggest
                you wait until you see a sale in your ClickBank account before
                you order your first boost. This guarnatees that your account is
                set up properly!
              </p>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Pricing;
