/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";

// reactstrap components
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import fetch from "cross-fetch";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      affiliateID: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
      phone: "",
      password: ""
    };
  }
  componentDidMount() {
    fetch('https://perpetualincomeengine.com/server/server.php?method=getPieUserDetails&Email=' + getCookie('user'))
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        this.setState ({
          email: user[0].Email,
          affiliateID: user[0].AffiliateID,
          firstName: user[0].FirstName,
          lastName: user[0].LastName,
          address: user[0].Address,
          city: user[0].City,
          country: user[0].Country,
          postalCode: user[0].PostalCode,
          password: user[0].Password,
          phone: user[0].Phone
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      fetch('https://perpetualincomeengine.com/server/server.php?method=setPieUserDetails&Email='
        + this.state.email
        + "&AffiliateID=" + this.state.affiliateID
        + "&FirstName=" + this.state.firstName
        + "&LastName=" + this.state.lastName
        + "&Address=" + this.state.address
        + "&City=" + this.state.city
        + "&Country=" + this.state.country
        + "&PostalCode=" + this.state.postalCode
        + "&Password=" + this.state.password
        + "&Phone=" + this.state.phone
      );
    }
  }
  handleChange = (target, event) => {
    let value = event.target.value.toLowerCase();
    value = value.replace(/[^a-z0-9]/gmi, "").replace(/\s+/g, "");
    this.setState({[target]: value});
  }
  render() {
    return (
      <>
        <h5 className="info-text">Enter Your CB Nickname</h5>
        <Row className="justify-content-center">
          <Col className="text-center" lg="5">
            <InputGroup className="input-group-focus">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="cbNickname"
                placeholder="Nickname"
                value={this.state.affiliateID} onChange={e => this.handleChange('affiliateID', e)}
                type="text"
              />
            </InputGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;
